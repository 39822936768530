import React from "react";
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"

const RenderTags = ({ group }) => {
    return (
        <div className="app-section-render-tags">
            <h5>Topics</h5>
            <ul>
                {group.map(tag => (
                    <li key={tag.fieldValue}>
                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                            {tag.fieldValue} ({tag.totalCount})
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default RenderTags